<template>
  <div>
    <v-dialog
      v-model="dialog"
      v-bind="$attrs"
    >
      <template #activator="{ on, attrs }">
        <slot 
          name="activator"
          :on="on"
          :attrs="attrs"
        />
      </template>

      <v-card :height="height">
        <v-toolbar flat>
          <base-button-icon
            right
            fixed
            icon="mdi-close"
            color="black"
            @click="dialog = false"
          />
        </v-toolbar>
        <slot name="content" />
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import BaseButtonIcon from '@/components/BaseButtonIcon'

export default {
  name: "BaseModal",
  components: {
    BaseButtonIcon
  },
  props: {
    height: {
      type: String,
      default: ""
    }
  },
  data () {
    return {
      dialog: false,
    }
  },
}
</script>